import { Tooltip } from '@sortlist-frontend/design-system/ssr';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { TFunction } from '@sortlist-frontend/translation/server-only';
import Image from 'next/image';
import { FC } from 'react';

const s3BucketUrl = process.env.NEXT_PUBLIC_S3_BUCKET;

type Props = {
  t: TFunction;
  className?: string;
  absolute?: boolean;
  spaceLeft?: string;
};

export const VerifiedFlag: FC<Props> = ({ className, absolute, spaceLeft, t }) => {
  return (
    <Tooltip placement="bottom" title={t('common:flags.verifiedTooltip_2')}>
      <Image
        className={`${className ?? ''} ${absolute ? 'flag-absolute' : ''} ${spaceLeft ?? ''} flag`}
        src={ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/flags/verified-flag-primary.svg`)}
        alt="verified-flag"
        height="28"
        width="22"
      />
    </Tooltip>
  );
};

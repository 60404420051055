import { Tooltip } from '@sortlist-frontend/design-system/ssr';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { TFunction } from '@sortlist-frontend/translation/server-only';
import Image from 'next/image';
import { FC } from 'react';

const s3BucketUrl = process.env.NEXT_PUBLIC_S3_BUCKET;

type Props = {
  t: TFunction;
  className?: string;
  absolute?: boolean;
};

export const CertifiedFlag: FC<Props> = ({ className, absolute, t }) => {
  return (
    <Tooltip placement="bottom" title={t('common:flags.certifiedTooltip')}>
      <Image
        className={`${className ?? ''} ${absolute ? 'flag-absolute' : ''} flag`}
        src={ImageSourceHelper.fromUrl(`${s3BucketUrl}/icons/flags/certified-flag-primary.svg`)}
        alt="certified-flag"
        height="28"
        width="22"
      />
    </Tooltip>
  );
};
